<template>
    <div>
        <b-row>
            <b-col
                    cols="12"
                    md="6"
                    class=" mb-1"
            >

                <b-table-simple class="table-details" responsive no-border-collapse>
                    <b-tbody>
                        <b-tr>
                            <b-td width="40%">{{$t('label_phone')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.phone}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_username')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.name}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_email_address')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.emailAddress}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_department')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.department? editedItem.department.name : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_position')}}</b-td>
                            <b-td class="actions">
                                {{editedItem.position}}
                            </b-td>
                        </b-tr>
                        <!--<b-tr>-->
                            <!--<b-td width="40%">{{$t('label_solicitor_above')}}</b-td>-->
                            <!--<b-td class="actions">-->

                                <!--{{editedItem.above_solicitor}}-->

                            <!--</b-td>-->
                        <!--</b-tr>-->

                        <!--<b-tr>-->
                            <!--<b-td width="40%">{{$t('label_street_and_house_number')}}</b-td>-->
                            <!--<b-td class="actions">-->

                                <!--{{editedItem.solicitor_data? editedItem.solicitor_data.street : ''}}-->

                            <!--</b-td>-->
                        <!--</b-tr>-->
                        <!--<b-tr>-->
                            <!--<b-td width="40%">{{$t('label_post_code')}}</b-td>-->
                            <!--<b-td class="actions">-->

                                <!--{{editedItem.solicitor_data?editedItem.solicitor_data.zip : ''}}-->

                            <!--</b-td>-->
                        <!--</b-tr>-->

                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col
                    cols="12"
                    md="6"
                    class=" mb-1"
            >
                <b-table-simple class="table-details" responsive  no-border-collapse>
                    <b-tbody>
                        <b-tr>
                            <b-td width="40%">{{$t('label_country')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.solicitor_data && editedItem.solicitor_data.country? editedItem.solicitor_data.country.countryName : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_city')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.solicitor_data? editedItem.solicitor_data.city : ''}}

                            </b-td>
                        </b-tr>
                        <!--<b-tr>-->
                            <!--<b-td width="40%">{{$t('label_invoice_details')}}</b-td>-->
                            <!--<b-td>-->
                                <!--{{editedItem.solicitor_data?editedItem.solicitor_data.invoice_details : ''}}-->
                            <!--</b-td>-->
                        <!--</b-tr>-->
                        <b-tr>
                            <b-td width="40%">{{$t('label_company_name')}}</b-td>
                            <b-td>
                                {{editedItem.solicitor_data?editedItem.solicitor_data.company_name : ''}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_nip')}}</b-td>
                            <b-td>
                                {{editedItem.solicitor_data?editedItem.solicitor_data.NIP : ''}}
                            </b-td>
                        </b-tr>
                        <!--<b-tr>-->
                            <!--<b-td width="40%">{{$t('label_iban')}}</b-td>-->
                            <!--<b-td>-->
                                <!--{{editedItem.solicitor_data?editedItem.solicitor_data.IBAN : ''}}-->
                            <!--</b-td>-->
                        <!--</b-tr>-->
                        <!--<b-tr>-->
                            <!--<b-td width="40%">{{$t('label_swift')}}</b-td>-->
                            <!--<b-td>-->
                                <!--{{editedItem.solicitor_data?editedItem.solicitor_data.SWIFT : ''}}-->
                            <!--</b-td>-->
                        <!--</b-tr>-->
                        <!--<b-tr>-->
                            <!--<b-td width="40%">{{$t('label_bank_name')}}</b-td>-->
                            <!--<b-td>-->
                                <!--{{editedItem.solicitor_data?editedItem.solicitor_data.bank_name : ''}}-->
                            <!--</b-td>-->
                        <!--</b-tr>-->
                        <!--<b-tr>-->
                            <!--<b-td width="40%">{{$t('label_account_number')}}</b-td>-->
                            <!--<b-td>-->
                                <!--{{editedItem.solicitor_data?editedItem.solicitor_data.account_number : ''}}-->
                            <!--</b-td>-->
                        <!--</b-tr>-->
                        <b-tr>
                            <b-td width="40%">{{$t('label_hourly_rate')}}</b-td>
                            <b-td>
                                {{editedItem.hourly_rate}}
                            </b-td>
                        </b-tr>


                    </b-tbody>
                </b-table-simple>



            </b-col>
        </b-row>



        <br>
        <!--<annex-modal @item-added="$emit('annex-added', $event)" :is-deal="is_deal" :edited-item="editedItem" :order-subjects="order_subjects"></annex-modal>-->
    </div>
</template>

<script>
    import {
        BPopover,
        BTableSimple, BTbody, BTr, BTd, BButton,BThead, BTh

    } from 'bootstrap-vue'

    // import annexModal from './../modals/annexModal'
    import { MODULE_PREFIX} from './../moduleHelper'
    export default {
        components: {
            BPopover,
            BTableSimple, BTbody, BTr, BTd, BButton,BThead, BTh,
            // annexModal
        },

        props: ['editedItem'],
        data() {
            return {

                MODULE_PREFIX,


                // order_subjects:[]
            }
        },
        methods:{
            getPageData(){
                // this.async('get', '/select_options', {params:{options:['order_subject']}}, function(resp){
                //     this.order_subjects = resp.data.order_subject;
                // });
            }
        },
        created() {

            // this.is_deal = this.$router.currentRoute.path.includes('/' + this.DEAL_PREFIX + '/');
            // this.MODULE_PREFIX = this.is_deal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
            this.getPageData();
        },
        // mounted(){
        //     this.editedItem = this.$store.state[this.AGREEMENT_DYNAMIC_STORE_NAME].tabItem;
        // }
    }
</script>