<template>

    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_edit_note'):$t('label_add_note')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_subject')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_subject')"

                    >
                        <b-form-input

                                v-model="itemData.title"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_subject')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_description')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_description')"

                    >
                        <b-form-textarea

                                :state="getValidationState(validationProps)"
                                v-model="itemData.description"
                        ></b-form-textarea>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_save'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
      BFormTextarea,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BInputGroupPrepend,
    } from 'bootstrap-vue'

    import { NOTE_PREFIX as PREFIX,  MODULE_PREFIX, USER_PREFIX} from './../moduleHelper'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            // vSelect
        },
        props: ['moduleItem', 'editedItem'],
        data() {
            return {

                USER_PREFIX,
                MODULE_PREFIX,
                PREFIX,

                action: 'adding',

                blankItemData: {

                    title: '',
                    description: '',
                    // deadline_at: '',
                    // solicitor_id:''
                },

                itemData: {},
            }
        },

        methods: {
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';
                    let item = Object.assign({}, this.blankItemData);
                    item.id = this.editedItem.id;
                    item.share_to_role = item.share_to_role?.split(',').filter(Boolean) ?? []
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }

                    this.itemData = item;
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);

                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {
                    this.itemData[this.USER_PREFIX+ '_id'] = this.moduleItem.id;
                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's', this.itemData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    this.async('put', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/' + this.itemData.id, this.itemData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                }

            }
        },

    }
</script>
