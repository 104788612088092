<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{action == 'editing'?$t('label_edit_document'):$t('label_add_document')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_document_name')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_document_name')"
                    >
                        <b-form-input
                                v-model="itemData.name"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_document_name')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_to_ocr')"
                        slim
                >
                    <b-form-group
                            label=""
                            label-for="meeting_type_id"
                    >

                        <b-form-checkbox
                                id="sol-doc-ocr"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.ocr"
                        >
                            <label for="sol-doc-ocr">{{ $t('label_to_ocr') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        :name="$t('label_document')"
                        slim
                        #default="validationProps"
                        :rules="editedItem? '' : 'required'"
                >
                    <file-input
                            v-model="document"
                            class="file--uploader size--big drop-block "
                            :max-files-count='1'
                            :multiple="false"
                            :allowed-extensions="false"
                            :max-file-size='100'
                            :url="''"
                    >
                        <template #uploadBody>
                            <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                            <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                            <span>{{$t('label_add_file')}}</span>
                        </template>

                        <template #errorBug="fileErrors">

                            <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>

                        </template>
                    </file-input>
                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>

                </validation-provider>

                <b-progress v-if="percentsHttpRequestDone" :value="percentsHttpRequestDone" max="100" show-progress animated></b-progress>

                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_save'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea,
        BFormGroup, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BFormInput, BProgress,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import fileInput from '@/views/components/fileUploadInput'

    import {DOCUMENT_PREFIX as PREFIX, MODULE_PREFIX} from './../moduleHelper'

    import {serialize} from 'object-to-formdata';

    export default {
        components: {

            BFormGroup, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BFormInput, BProgress,

            ValidationProvider,
            ValidationObserver,
            fileInput,
            vSelect

        },

        props: ['moduleItem', 'editedItem'],

        data() {
            return {

                MODULE_PREFIX,
                PREFIX,
                // docTypes,

                action: 'adding',

                blankItemData: {

                    name: '',
                    ocr: 0,
                    // doc_type:''
                },

                document: [],

                itemData: false

            }
        },

        methods: {
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';

                    let item = Object.assign({}, this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }
                    item.name =  this.editedItem.display_name;
                    item.doc_type =  this.editedItem.type;

                    this.itemData = item;

                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData)
                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.document = [];
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);
                formData.append('item_id', this.moduleItem.id);
                if (this.document.length > 0) {
                    formData.append('document[]', this.document[0], this.document[0].name);
                }

                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's', formData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    }, false, {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                } else {
                    formData.append('id', this.editedItem.id);
                    this.async('put', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/' + this.itemData.id,formData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                }

            }
        },

    }
</script>
